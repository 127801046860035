import React from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedinIcon from '@mui/icons-material/LinkedIn';

const Footer = (props) => (
    <Box
        sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
            flexDirection: "row",
            width: "100%",
            height: "64.5px",
            backgroundColor: "#ffffff",
            color: "#000000",
            fontSize: "12px",
            fontWeight: "bold",
            padding: "10px",
            marginTop: "20px",
            bottom: 0,
            position: "relative",
            mt: "auto",
            boxShadow: "0px -3px 3px rgba(0, 0, 0, 0.25)",
        }}
    >
        <Grid container spacing={2}>
            <Grid item xs={3} sm={4} sx={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{ width: "min-content", height: "min-content", minWidth: "0", padding: "4px", mr: 1 }}
                    size="small"
                    onClick={() => window.open("https://www.instagram.com/slidenotes.dev/", "_blank" ).focus()}
                >
                    <InstagramIcon/>
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{ width: "min-content", height: "min-content", minWidth: "0", padding: "4px" }}
                    size="small"
                    onClick={() => window.open("https://www.linkedin.com/company/slidenotes", "_blank" ).focus()} 
                >
                    <LinkedinIcon/>
                </Button>
            </Grid>

            <Grid item xs={5} sm={4} sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Typography variant="p" sx={{color: "gray"}}>
                    © 2024 SlideNotes<br/>All Rights Reserved
                </Typography>
            </Grid>

            <Grid item xs={4} sm={4} sx={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                <Button
                    variant="text"
                    color="secondary"
                    size="small"
                    sx={{textTransform: "none"}}
                    onClick={() => props.onTermsAndConditions()}
                >
                    Terms and Conditions
                </Button>
                <Button
                    variant="text"
                    color="secondary"
                    size="small"
                    sx={{textTransform: "none"}}
                    onClick={() => props.onPrivacyPolicy()}
                >
                    Privacy Policy
                </Button>
            </Grid>
        </Grid>
    </Box>
);

export default Footer;