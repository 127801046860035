import React from 'react'
import { Container, Typography } from '@mui/material'

function TermsAndConditionsPage() {
    return (
      <Container sx={{p: "20px"}}>
        <Typography variant="h3">Terms and Conditions of Service for SlideNotes</Typography>
        <Typography variant="h4">Effective Date: 1/1/2024</Typography>
        <Typography variant="h5">1. Acceptance of Terms</Typography>
        <Typography variant="p">By accessing this website, you agree to be bound by these Terms and Conditions of Use, all applicable laws and regulations, and acknowledge your responsibility for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site.</Typography>
        <Typography variant="h5">2. User Accounts</Typography>
        <Typography variant="p">To use specific features of the service, users may need to create an account. Users are responsible for maintaining the confidentiality of their account information and must provide accurate and complete information when creating an account.</Typography>
        <Typography variant='h5'>3. Intellectual Property</Typography>
        <Typography variant='p'>The content on SlideNotes, including text, graphics, logos, and images, constitutes the intellectual property of SlideNotes and is protected by copyright laws.</Typography>
        <Typography variant='h5'>4. Prohibited Conduct</Typography>
        <Typography variant='p'>Users agree not to:<br/>Violate any applicable laws or regulations.<br/>Upload any content that is infringing, defamatory, or violates the rights of others.<br/>Use the service for any illegal or unauthorized purpose.<br/>Attempt to gain unauthorized access to SlideNotes' systems or networks.<br/>Attempt to decompile or reverse engineer any software contained on SlideNotes' website.<br/>Use SlideNotes in a manner that exceeds the scope of the purchased services.<br/>This license shall automatically terminate if you violate any of these restrictions and may be terminated by SlideNotes at any time. Upon termination, you must destroy any downloaded materials in your possession, whether in electronic or printed format.</Typography>
        <Typography variant='h5'>5. Privacy Policy</Typography>
        <Typography variant='p'>SlideNotes collects and uses personal information as described in its Privacy Policy.</Typography>
        <Typography variant='h5'>6. Termination</Typography>
        <Typography variant='p'>SlideNotes reserves the right to terminate or suspend access to the service at its discretion, without notice.</Typography>
        <Typography variant='h5'>7. Limitations</Typography>
        <Typography variant='p'>In no event shall SlideNotes or its suppliers be liable for any damages, including, without limitation, damages for loss of data or profit, or due to business interruption, arising out of the use or inability to use the materials on SlideNotes' website.</Typography>
        <Typography variant='h5'>8. Disclaimers</Typography>
        <Typography variant='p'>SlideNotes makes no warranties or representations about the accuracy or completeness of the content. The service is provided "as is" without any warranty.</Typography>
        <Typography variant='h5'>9. Definition of Lifetime Customer Subscription</Typography>
        <Typography variant='p'>A Lifetime Customer Subscription refers to the duration of SlideNotes' operational existence. SlideNotes reserves the right to terminate its services at any time, at which point the Lifetime Customer Subscription will be considered fulfilled.</Typography>
        <Typography variant='h5'>10. Governing Law</Typography>
        <Typography variant='p'>Any claim relating to SlideNotes' website shall be governed by the laws of the State of California without regard to its conflict of law provisions.</Typography>
        <Typography variant='h5'>11. Changes to Terms</Typography>
        <Typography variant='p'>SlideNotes reserves the right to modify these Terms and Conditions at any time. Users are responsible for regularly reviewing the terms. By using this website, users agree to be bound by the current version of these Terms and Conditions.</Typography>
        <Typography variant='h5'>12. Contact Information</Typography>
        <Typography variant='p'>For questions about these Terms and Conditions, please contact us at contactus@slidenotes.dev.</Typography>
        <Typography variant='h5'>13. Entire Agreement</Typography>
        <Typography variant='p'>These Terms and Conditions constitute the entire agreement between the user and SlideNotes with respect to the service and supersede all prior or contemporaneous communications and proposals, whether electronic, oral, or written, between the user and SlideNotes with respect to the service.</Typography>
      </Container>
    )
}

export default TermsAndConditionsPage