import React from "react";
import {
  Modal,
  Box,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import OtpInput from "react-otp-input";
import CloseIcon from "@mui/icons-material/Close";
import AlertBar from "./AlertBar";

function LoginModal(props) {
  const [step, setStep] = React.useState("phone");
  const [otp, setOtp] = React.useState("");
  const [methodId, setMethodId] = React.useState("");
  const [newCodeBtn, setNewCodeBtn] = React.useState(false);
  const [alert, setAlert] = React.useState("");
  const [alertText, setAlertText] = React.useState("");
  const [phone, setPhone] = React.useState("");

  const onPhoneSubmit = React.useCallback(() => {
    setStep("loading");
    const phoneRegex = /^\+1[1-9]\d{9}$/;
    if (("+" + phone)?.match(phoneRegex)) {
      const url =
        "https://kodexs1t24.execute-api.us-west-2.amazonaws.com/Stage/sendPhoneCode";
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ phoneNumber: "+" + phone }),
      };
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setStep("otp");
          setMethodId(data["method_id"]);
        })
        .catch((err) => {
          setStep("phone");
          setAlertText("Unknown error");
          setAlert("error");
          console.log(err.message);
        });
    } else {
      setStep("phone");
      setAlertText("Enter a valid US phone number");
      setAlert("error");
    }
  }, [phone]);

  const onOtpSubmit = React.useCallback(() => {
    setStep("loading");
    if (otp.length === 6) {
      const url =
        "https://kodexs1t24.execute-api.us-west-2.amazonaws.com/Stage/checkPhoneCode";
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          phoneNumber: "+" + phone,
          otp: otp,
          method_id: methodId,
        }),
      };
      fetch(url, requestOptions)
        .then((response) => {
          setOtp("");
          if (response.status === 404) {
            throw new Error("Incorrect code");
          } else if (response.status === 401) {
            throw new Error("Timeout");
          } else {
            return response.json();
          }
        })
        .then((data) => {
          props.onLogin(data["sessionToken"]);
          props.onClose();
          setStep("phone");
        })
        .catch((err) => {
          setStep("otp");
          if (err.message === "Incorrect code") {
            setAlertText("Incorrect code. Please try again.");
          } else if (err.message === "Timeout") {
            setAlertText(
              "Code not submitted within two minutes. Please try again."
            );
          } else {
            setAlertText("Unknown error");
          }
          setAlert("error");
          console.log(err.message);
        });
    } else {
      setAlertText("Enter the six digit code sent to your phone number");
      setAlert("error");
      setStep("otp");
    }
  }, [methodId, otp, phone, props]);

  React.useEffect(() => {
    if (step === "otp") {
      if (alert === "error") {
        setNewCodeBtn(true);
      } else if (!newCodeBtn) {
        setTimeout(() => setNewCodeBtn(true), 30000);
      }
    } else {
      setNewCodeBtn(false);
    }
  }, [step, alert, newCodeBtn]);

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box
        sx={{
          outline: "none",
          height: "auto",
          minHeight: "300px",
          width: { xs: "80%", sm: "70%", md: "65%", lg: "50%" },
          bgcolor: "background.default",
          py: { xs: 1, sm: 2 },
          px: { xs: 1, sm: 4 },
          boxShadow: 24,
          border: "2px solid #000",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Button
          onClick={props.onClose}
          size="small"
          color="secondary"
          sx={{ position: "absolute", top: "0", right: "-15px" }}
        >
          <CloseIcon />
        </Button>
        <AlertBar
          onClose={() => setAlert("")}
          alert={alert}
          alertText={alertText}
        />
        {step === "phone" && (
          <>
            <form
              height="auto"
              onKeyDown={(e) => {
                e.key === "Enter" && onPhoneSubmit();
              }}
            >
              <Grid
                container
                spacing={{ xs: 2, sm: 4 }}
                sx={{
                  height: "100%",
                  flexDirection: "row",
                  flexGrow: 1,
                  alignContent: "space-between",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  p: 3,
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h4" align="center" sx={{fontFamily: "Bayon"}}>
                    You must be logged in to use SlideNotes
                  </Typography>
                  <Typography align="center">
                    Enter your phone number below for a one time code
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <PhoneInput
                      country={"us"}
                      onlyCountries={["us"]}
                      value={phone}
                      onChange={setPhone}
                      specialLabel={""}
                      inputStyle={{ width: "100%" }}
                      countryCodeEditable={false}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={() => onPhoneSubmit()}
                    variant="contained"
                    color="secondary"
                    sx={{ width: { xs: "60%", sm: "60%", md: "40%" } }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </>
        )}
        {step === "otp" && (
          <>
            <form
              onKeyDown={(e) => {
                e.key === "Enter" && onOtpSubmit();
              }}
            >
              <Grid
                container
                columnSpacing={0}
                rowSpacing={{ xs: newCodeBtn ? 2 : 4, sm: 4 }}
                sx={{
                  height: "100%",
                  flexDirection: "row",
                  flexGrow: 1,
                  alignContent: "space-between",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  p: 3,
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h4" align="center" sx={{fontFamily: "Bayon"}}>
                    Enter your one time code below
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      inputType={"tel"}
                      inputStyle={{
                        width: "10vmin",
                        height: "10vmin",
                        fontSize: "20px",
                      }}
                      renderInput={(props) => <input {...props} />}
                      containerStyle={{ width: "auto", height: "auto" }}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={newCodeBtn ? 6 : 12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={() => onOtpSubmit()}
                    variant="contained"
                    color="secondary"
                    sx={{
                      width: { xs: "60%", sm: newCodeBtn ? "85%" : "60%", md: newCodeBtn ? "70%" : "40%" },
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
                {newCodeBtn && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      onClick={() => onPhoneSubmit()}
                      variant="outlined"
                      color="secondary"
                      sx={{ width: { xs: "60%", sm: "85%", md: "70%" } }}
                    >
                      Send New Code
                    </Button>
                  </Grid>
                )}
              </Grid>
            </form>
          </>
        )}
        {step === "loading" && (
          <>
            <CircularProgress color="secondary" />
          </>
        )}
      </Box>
    </Modal>
  );
}

export default LoginModal;
