import React from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import { RWebShare } from "react-web-share";
import getNotes from "../helpers/getNotes.js";
import downloadNotes from "../helpers/downloadNotes.js";
import LoadingPage from "./LoadingPage.js";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RemoveModal from "../components/DeleteModal.js";
import deleteNotes from "../helpers/deleteNotes.js";
import SurveyCard from "../components/SurveyCard.js";

function NotesPage(props) {
  const exampleNoteIds = ["c951f8dc-107f-4266-bea6-11116ed04d20","69110e4e-d113-4ca7-a182-ed1d44c14fbc","cd737f4c-7235-4590-a824-0c4d88b44814"]

  const [title, setTitle] = React.useState("");
  const [notes, setNotes] = React.useState("");
  const [removeModalOpen, setRemoveModalOpen] = React.useState(false);

  React.useEffect(() => {
    getNotes(props.id, setTitle, setNotes);
  }, [props.id]);

  const onDownload = () => {
    downloadNotes(props.id, notes);
  }

  const onRemove = React.useCallback(() => {
    try {
      deleteNotes(props.id);
    } catch (err) {
      props.createAlert("error", err.message);
    }
  }, [props])

  const closeRemoveModal = () => {
    setRemoveModalOpen(false)
  }

  return (
    <>
      <RemoveModal open={removeModalOpen} onClose={() => closeRemoveModal()} onRemove={() => onRemove()}/>
      {title.length > 0 ? (
        <Grid
          container
          spacing={2}
          sx={{ alignContent: "flex-start", flexWrap: "wrap", p: 3, height: "max-content" }}
        >
          <Grid item xs={12}>
            <Button
                variant="contained"
                color="secondary"
                onClick={() => (window.location.href = "/")}
              >
                <ArrowBackIcon sx={{fontSize: "20px" }}/>
              </Button>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Typography noWrap variant="h1" sx={{fontFamily: "Bayon", fontSize: {xs: "20px", sm: "30px", md: "40px"}}}>
              {title}
            </Typography>
            <Box
              sx={{
                pt: 1,
                overflowY: "scroll",
                height: "min-content",
                bgcolor: "background.default",
              }}
            >
              <Typography sx={{ whiteSpace: "pre-wrap" }}>{notes}</Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            sx={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: {
                  xs: "space-between",
                  sm: "flex-start",
                  md: "flex-end",
                },
              }}
            >
              <RWebShare
                data={{
                  text: "Check out my notes for " + title,
                  url: window.location.href,
                  title: "Share your notes on " + title,
                }}
                sites={["whatsapp", "telegram", "mail", "copy"]}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ width: { xs: "49%", sm: "90%" } }}
                >
                  Share
                </Button>
              </RWebShare>
              <Button
                variant="contained"
                color="secondary"
                sx={{ mt: { xs: 0, sm: 1 }, width: { xs: "49%", sm: "90%" } }}
                onClick={onDownload}
              >
                Download
              </Button>
              {/*<Button
                variant="text"
                color="secondary"
                sx={{ mt: { xs: 0, sm: 1 }, width: { xs: "100%", sm: "90%" } }}
                onClick={() => setRemoveModalOpen(true)}
              >
                Delete
              </Button>*/}
              {/* For Survey
              !exampleNoteIds.includes(props.id) && <SurveyCard 
                sx = {{
                  mt: { xs: 1.5, sm: 1 },
                  width: { xs: "100%", sm: "90%" }
                }}
                openSurvey={() => props.openSurvey()}
              />
              */}
            </Box>
          </Grid>
        </Grid>
      ) : (
        <LoadingPage/>
      )}
    </>
  );
}

export default NotesPage;
