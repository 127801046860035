const getNotes = (id, _setTitle, _setNotes) => {
    const url =
      "https://kodexs1t24.execute-api.us-west-2.amazonaws.com/Stage/getNotes";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: id }),
    };
    fetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Error");
      })
      .then((data) => {
        _setTitle(data["s3key"].split(".")[0])
        _setNotes(data["notes_text"])
      })
      .catch((err) => {
        console.log(err.message);
      });
}

export default getNotes