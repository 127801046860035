import React from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
 
function RemoveModal(props) {
  const [loading, setLoading] = React.useState(false);

  const onRemove = () => {
    setLoading(true);
    props.onRemove();
  }

  const onClose = () => {
    props.onClose();
  }

  return (
    <Modal
      open={props.open}
      onClose={() => onClose()}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box
        sx={{
          outline: "none",
          height: "auto",
          minHeight: "300px",
          width: { xs: "80%", sm: "80%", md: "80%", lg: "70%" },
          bgcolor: "background.default",
          py: { xs: 1, sm: 2 },
          px: { xs: 2, sm: 4 },
          boxShadow: 24,
          border: "2px solid #000",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
      { loading ? <CircularProgress color="secondary" /> : 
        <>
          <Button
            onClick={() => onClose()}
            size="small"
            sx={{ position: "absolute", top: "0", right: "-15px" }}
          >
            <CloseIcon color="secondary"/>
          </Button>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column"
            }}
          >
            <Typography variant="h3" sx={{fontFamily: "Bayon", fontSize: {xs: "25px", sm:"25px", md:"30px", lg:"40px"}, textAlign: "center"}}>
              Are you sure you want to delete this note?
            </Typography>
            <Button  
              variant="contained"
              color="secondary"
              onClick={() => onRemove()}
              size="large"
              sx={{mt:"10px"}}
            >        
              Yes, Delete It
            </Button>
          </Box>
        </>
      }
      </Box>
    </Modal>
  );
}

export default RemoveModal;
