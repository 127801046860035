import React from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function SurveyModal(props) {
  const onTakeSurvey = () => {
    const url = "https://scu.az1.qualtrics.com/jfe/form/SV_bsaTagWAjoSkIlw"
    window.open(url, "_blank").focus();
  };

  return (
    <Modal
      open={props.open}
      onClose={() => props.onClose()}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box
        sx={{
          outline: "none",
          height: "auto",
          minHeight: "300px",
          width: { xs: "80%", sm: "80%", md: "80%", lg: "70%" },
          bgcolor: "background.default",
          py: { xs: 1, sm: 2 },
          px: { xs: 2, sm: 4 },
          boxShadow: 24,
          border: "2px solid #000",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          position: "relative",
          flexDirection: "column",
        }}
      >
        <Typography variant="h4" sx={{ fontFamily: "Bayon" }}>
          Win a Year of SlideNotes Premium!
        </Typography>
        <Typography variant="h6" >
          Help us tailor SlideNotes to better meet your needs. Take this short survey to provide your valuable feedback on our features and services, and you'll be entered to win a full year of SlideNotes Premium for free.
        </Typography>
        <Typography variant="h5" sx={{ fontFamily: "Bayon" }}>
          Your input is crucial—thank you for helping us improve!
        </Typography>
        <Button
          onClick={() => props.onClose()}
          size="small"
          sx={{ position: "absolute", top: "0", right: "-15px" }}
        >
          <CloseIcon color="secondary" />
        </Button>
        <Button
          onClick={() => onTakeSurvey()}
          size="large"
          variant="contained"
          color="secondary"
        >
          <Typography variant="h5" sx={{ fontFamily: "Bayon" }}>
            Take Survey
          </Typography>
        </Button>
      </Box>
    </Modal>
  );
}

export default SurveyModal;
