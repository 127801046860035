import React from 'react'
import { Container, Typography } from '@mui/material'

function PrivacyPolicyPage() {
    return (
      <Container sx={{p: "20px"}}>
        <Typography variant="h3">Privacy Policy for SlideNotes</Typography>
        <Typography variant="h4">Effective Date: 1/1/2024</Typography>
        <Typography variant="p">SlideNotes is committed to protecting the privacy of its users. This Privacy Policy outlines the types of personal information we collect, how it is used, and the steps we take to ensure your privacy is maintained.</Typography>
        <Typography variant="h5">Information We Collect</Typography>
        <Typography variant="h6">Personal Information</Typography>
        <Typography variant="p">When you sign up for an account on SlideNotes or subscribe, we may collect personal information such as your name, phone number, and email address</Typography>
        <Typography variant="h6">Usage Information</Typography>
        <Typography variant="p">We collect information about how you interact with our service, including the files you upload, the time it was uploaded, the features you use, and the actions you take.</Typography>
        <Typography variant="h5">How We Use Your Information</Typography>
        <Typography variant="h6">Providing Services</Typography>
        <Typography variant="p">We use your personal information to provide you with access to SlideNotes and its features.</Typography>
        <Typography variant="h6">Improving Services</Typography>
        <Typography variant="p">We analyze usage data to enhance our services, improve user experience, and optimize our platform.</Typography>
        <Typography variant="h6">Communication</Typography>
        <Typography variant="p">We may use your email address or phone number to send you important updates, notifications, or promotional materials.</Typography>
        <Typography variant="h6">Transfer of Data</Typography>
        <Typography variant="p">Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those from your jurisdiction.<br/>If you are located outside the United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to the United States and process it there.<br/>No data, whether personal or non-personal, will be transferred without your consent.<br/>SlideNotes will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy.</Typography>
        <Typography variant="h5">Information Security</Typography>
        <Typography variant="p">SlideNotes takes precautions to protect your information. However, no method of transmission over the internet or electronic storage is entirely secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</Typography>
        <Typography variant="h5">Sharing Your Information</Typography>
        <Typography variant="p">SlideNotes will not share your personal information with third parties without your consent, except where required by law.</Typography>
        <Typography variant="h5">Your Choices</Typography>
        <Typography variant="p">You may terminate your account and all associated data by emailing us at contactus@slidenotes.dev.<br/>You can unsubscribe from any emails with a simple click in the email or in writing.</Typography>
        <Typography variant="h5">Changes to Privacy Policy</Typography>
        <Typography variant="p">SlideNotes reserves the right to modify this Privacy Policy at any time. Users are responsible for regularly reviewing the policy. By using this website, users agree to be bound by the current version of this Privacy Policy.</Typography>
        <Typography variant="h5">Contact Information</Typography>
        <Typography variant="p">For questions about this Privacy Policy, please contact us at contactus@slidenotes.dev.</Typography>
        <Typography variant="h5">Entire Agreement</Typography>
        <Typography variant="p">This Privacy Policy constitutes the entire agreement between the user and SlideNotes with respect to the service and supersedes all prior or contemporaneous communications and proposals, whether electronic, oral, or written, between the user and SlideNotes with respect to the service.</Typography>
      </Container>
    )
}

export default PrivacyPolicyPage