import React from 'react';
import { Box } from '@mui/material';
import Carousel, {slidesToShowPlugin} from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import './TestimonialsCarousel.css';
import MaddieLTestimonial from '../static/MaddieLTestimonial.png';
import MadeleineCTestimonial from '../static/MadeleineCTestimonial.png';
import RobertDTestimonial from '../static/RobertDTestimonial.png';
import MichelleGTestimonial from '../static/MichelleGTestimonial.png';
import LukePTestimonial from '../static/LukePTestimonial.png';
import YufangGTestimonial from '../static/YufangGTestimonial.png';

const TestimonialsCarousel = () => (
    <Carousel
        plugins={[
            'arrows', 
            'infinite',
            {
                resolve: slidesToShowPlugin,
                options: {
                    numberOfSlides: 3
                }
            },
        ]}
        breakpoints={{
            850: {
              plugins: [
                'arrows', 
                'infinite',
               {
                    resolve: slidesToShowPlugin,
                    options: {
                        numberOfSlides: 1
                    }
               },
             ]
            },
            1285: {
              plugins: [
                'arrows', 
                'infinite',
                {
                    resolve: slidesToShowPlugin,
                    options: {
                        numberOfSlides: 2
                    }
               },
             ]
            }
          }}
    >
        <Box
            component="img"
            src={MaddieLTestimonial}
            sx={{width: "95%"}}
        />
        <Box
            component="img"
            src={MadeleineCTestimonial}
            sx={{width: "95%"}}
        />
        <Box
            component="img"
            src={RobertDTestimonial}
            sx={{width: "95%"}}
        />
        <Box
            component="img"
            src={MichelleGTestimonial}
            sx={{width: "95%"}}
        />
        <Box
            component="img"
            src={LukePTestimonial}
            sx={{width: "95%"}}
        />
        <Box
            component="img"
            src={YufangGTestimonial}
            sx={{width: "95%"}}
        />
    </Carousel>
);

export default TestimonialsCarousel;