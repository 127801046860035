const deleteNotes = (id) => {
    const url =
      "https://kodexs1t24.execute-api.us-west-2.amazonaws.com/Stage/deleteNotes";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: id }),
    };
    fetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          window.location.href = "/"
          return;
        }
        throw new Error("Unable to remove notes");
      })
      .catch((err) => {
        console.log(err.message);
      });
}

export default deleteNotes