const handleManageSubscribe = () => {
  const url =
    "https://kodexs1t24.execute-api.us-west-2.amazonaws.com/Stage/createStripePortalSession";
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      sessionToken: localStorage.getItem("sessionToken"),
      returnUrl: window.location.href,
    }),
  };
  fetch(url, requestOptions)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Error");
    })
    .then((data) => {
      window.location.href = data["url"];
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export default handleManageSubscribe;
