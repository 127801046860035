import { Grid, CircularProgress } from '@mui/material'

function LoadingPage() {
    return (<Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          alignItems: "center",
          alignContent: "center",
          justifyContent: "center",
          height: "calc(100vh - 129px)",
        }}
      >
        <CircularProgress color="secondary" />
      </Grid>)
}

export default LoadingPage