import React from "react";
import { Snackbar, Alert } from "@mui/material";

function AlertBar(props) {
  return (
    <Snackbar
      open={props.alert.length > 0}
      autoHideDuration={5000}
      onClose={props.onClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        onClose={props.onClose}
        severity={props.alert.length > 0 ? props.alert : "error"}
        sx={{ width: "100%" }}
      >
        {props.alertText}
      </Alert>
    </Snackbar>
  );
}

export default AlertBar;
