import React from "react";
import { Box } from "@mui/material";
import AlertBar from "./components/AlertBar.js";
import NotesPage from "./pages/NotesPage.js";
import ProgressPage from "./pages/ProgressPage.js";
import LandingPage from "./pages/LandingPage.js";
import HomePage from "./pages/HomePage.js";
import Navbar from "./components/Navbar.js";
import LoginModal from "./components/LoginModal.js";
import PremiumModal from "./components/PremiumModal.js";
import handleSubscribe from "./helpers/handleSubscribe.js";
import handleManageSubscribe from "./helpers/handleManageSubscribe.js";
import "./App.css";
import Footer from "./components/Footer.js";
import LoadingPage from "./pages/LoadingPage.js";
import TermsAndConditionsPage from "./pages/TermsAndConditionsPage.js";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage.js";
import SurveyModal from "./components/SurveyModal.js";

function App() {
  const [page, setPage] = React.useState(null);
  const [loginModalOpen, setLoginModalOpen] = React.useState(false);
  const [premiumModalOpen, setPremiumModalOpen] = React.useState(false);
  const [surveyModalOpen, setSurveyModalOpen] = React.useState(false);
  const [premium, setPremium] = React.useState(false);
  const [subscribed, setSubscribed] = React.useState(false);
  //const [premium, setPremium] = React.useState(true);
  const [loggedIn, setLoggedIn] = React.useState(null);
  //const [loggedIn, setLoggedIn] = React.useState(true);
  const [alert, setAlert] = React.useState("");
  const [anchor, setAnchor] = React.useState("");

  const openSurvey = () => {
    const url = "https://scu.az1.qualtrics.com/jfe/form/SV_bsaTagWAjoSkIlw"
    window.open(url, "_blank").focus();
  };

  const landingPage = React.useCallback(() => {
    return <LandingPage handleGetStarted={() => setLoginModalOpen(true)} />;
  }, []);

  let homePage = null;

  const createAlert = React.useCallback(
    (type, message) => {
      setAlert(
        <AlertBar
          onClose={() => setAlert(null)}
          alert={type}
          alertText={message}
        />
      );
      if (type === "error") {
        setPage(homePage);
      }
    },
    [homePage]
  );

  const progressPage = React.useCallback(
    (file) => {
      setPage(<ProgressPage file={file} createAlert={createAlert} />);
    },
    [createAlert]
  );

  const notesPage = React.useCallback((id) => {
    return <NotesPage id={id} openSurvey={() => openSurvey()}/>;
  }, []);

  const logout = React.useCallback(() => {
    setLoggedIn(false);
    setPremium(false);
    setPage(landingPage);
    localStorage.clear();
  }, [landingPage]);

  React.useEffect(() => {
    const foundToken = localStorage.getItem("sessionToken");
    if (!foundToken) {
      setLoggedIn(false);
      return;
    }
    const queryParams = new URLSearchParams(window.location.search);
    window.history.replaceState('', document.title, window.location.href.split("?")[0])
    const paymentSuccess = queryParams.get("paymentSuccess") === "true";
    const oneTime = queryParams.get("oneTime") === "true";
    const url =
      "https://kodexs1t24.execute-api.us-west-2.amazonaws.com/Stage/getUser";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ sessionToken: foundToken }),
    };

    var retries = 0;
    const maxRetries = 5;

    var getUser = setInterval(() => {
      fetch(url, requestOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Error");
        })
        .then((data) => {
          const yearThreeThousand = 32503708800;
          if ((paymentSuccess && !data["premium"]) || (oneTime && data["premiumPaidTime"] !== yearThreeThousand)) {
            retries++;
            if (retries > maxRetries) {
              logout();
              window.history.replaceState('', document.title, "/");
              clearInterval(getUser)
            }
            return;
          }
          setPremium(data["premium"]);
          setSubscribed(data["premium"] && data["premiumPaidTime"] !== yearThreeThousand);
          setLoggedIn(true);
          clearInterval(getUser);
        })
        .catch((err) => {
          logout();
          console.log(err.message);
        });
    }, 2000);
  }, [logout]);

  homePage = React.useCallback(() => {
    return (
      <HomePage
        progressPage={progressPage}
        createAlert={createAlert}
        premium={premium}
        setPremium={setPremium}
        openSurvey={() => openSurvey()}
        openSurveyModal={() => setSurveyModalOpen(true)}
      />
    );
  }, [createAlert, premium, progressPage]);

  React.useEffect(() => {
    const path = window.location.pathname;
    if (path.length > 1) {
      const uuidRegex =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
      const possibleId = path.slice(1);
      if (possibleId.match(uuidRegex)) {
        !page && setPage(notesPage(possibleId));
      }
    } else if (loggedIn != null) {
      !page && (loggedIn ? setPage(homePage) : setPage(landingPage));
    }
  }, [page, loggedIn, homePage, landingPage, notesPage]);

  React.useEffect(() => {
    if (alert === "error") {
      setPage(homePage);
    }
  }, [alert, homePage]);

  React.useEffect(() => {
    if (anchor === "") {
      window.history.replaceState('', document.title, window.location.href.split('#')[0])
    } else {
      window.location.href = "#" + anchor
      setAnchor("")
    }
  }, [anchor, setAnchor])

  function login(sessionToken) {
    localStorage.setItem("sessionToken", sessionToken);
    window.location.href = '/'
  }

  const onManageSubscribe = () => {
    setPage(<LoadingPage />);
    handleManageSubscribe()
  }

  const onLifetimeSubscribe = () => {
    setPage(<LoadingPage />)
    handleSubscribe("oneTime", () => {})
  }

  const onTestimonials = () => {
    setPage(landingPage)
    setAnchor("Testimonials")
  }

  const onFaq = () => {
    setPage(landingPage)
    setAnchor("FAQ")
  }

  const onTermsAndConditions = () => {
    setPage(<TermsAndConditionsPage />)
    window.scroll(0,0)
  }

  const onPrivacyPolicy = () => {
    setPage(<PrivacyPolicyPage />)
    window.scroll(0,0)
  }

  return (
    <Box
      sx={{
        height: "100%",
        minHeight: "100vh",
      }}
    >
      {alert}
      <LoginModal
        open={loginModalOpen}
        onClose={() => setLoginModalOpen(false)}
        onLogin={login}
      />
      <PremiumModal
        open={premiumModalOpen}
        premium={premium}
        onClose={() => setPremiumModalOpen(false)}
        onSubscribe={(pricingType) => handleSubscribe(pricingType, () => setPremiumModalOpen(false))}
      />
      <SurveyModal
        open={surveyModalOpen}
        onClose={() => setSurveyModalOpen(false)}
      />
      <Navbar
        loggedIn={loggedIn}
        login={() => setLoginModalOpen(true)}
        logout={logout}
        premium={premium}
        subscribed={subscribed}
        onSubscribe={() => setPremiumModalOpen(true)}
        onManageSubscribe={() => onManageSubscribe()}
        onLifetimeSubscribe={() => onLifetimeSubscribe()}
        onTestimonials={() => onTestimonials()}
        onFaq={() => onFaq()}
      />
      <Box sx={{minHeight: "calc(100vh - 129px)"}}>
        {page || 
          <LoadingPage /> 
        }
      </Box>
      <Footer 
        onTermsAndConditions={() => onTermsAndConditions()}
        onPrivacyPolicy={() => onPrivacyPolicy()}
      />
    </Box>
  );
}

export default App;
