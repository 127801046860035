import React from "react";
import {
  Modal,
  Box,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CloseIcon from "@mui/icons-material/Close";

function PremiumModal(props) {
  const [loading, setLoading] = React.useState(false);
  const [upgrade, setUpgrade] = React.useState(false)

  const onSubscribe = (pricingType) => {
    setLoading(true);
    props.onSubscribe(pricingType);
  }

  const onUpgrade = () => {
    setUpgrade(true);
  };

  const onClose = () => {
    setUpgrade(false);
    props.onClose();
  }

  return (
    <Modal
      open={props.open}
      onClose={() => onClose()}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box
        sx={{
          outline: "none",
          height: "auto",
          minHeight: "300px",
          width: { xs: "80%", sm: "80%", md: "80%", lg: "70%" },
          bgcolor: "background.default",
          py: { xs: 1, sm: 2 },
          px: { xs: 2, sm: 4 },
          boxShadow: 24,
          border: "2px solid #000",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        {loading ? (
          <CircularProgress color="secondary"/>
        ) : upgrade ? (
          <>
            <Button
              onClick={() => onClose()}
              size="small"
              sx={{ position: "absolute", top: "0", right: "-15px" }}
            >
              <CloseIcon color="secondary"/>
            </Button>
            <Grid container spacing={{xs: 4, sm: 4, md: 8, lg: 12}}>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="h4"
                  align="center"
                  sx={{ fontFamily: "Bayon" }}
                >
                  Monthly
                </Typography>
                <Box sx={{
                  display: "flex",
                  direction: "column",
                  justifyContent: "center",
                  py: "5px"
                }}>
                  <Typography variant="h5" sx={{px: "10px"}}>
                    $4.99
                  </Typography>
                  <Typography>
                    per month
                  </Typography>
                </Box>
                <Box
                  sx={{display: "flex", justifyContent: "center"}}
                >
                  <Button variant="contained" color="secondary" onClick={() => onSubscribe('monthly')}>
                    Subscribe Monthly
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="h4"
                  align="center"
                  sx={{ fontFamily: "Bayon" }}
                >
                  Yearly
                </Typography>
                <Box sx={{
                    display: "flex",
                    direction: "column",
                    justifyContent: "center",
                    py: "5px"
                  }}>
                  <Typography variant="h5" sx={{px: "10px"}}>
                    $29.99
                  </Typography>
                  <Typography>
                    per year
                  </Typography>
                </Box>
                <Box
                  sx={{display: "flex", justifyContent: "center"}}
                >
                  <Button variant="contained" color="secondary" onClick={() => onSubscribe('yearly')}>
                    Subscribe Yearly
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="h4"
                  align="center"
                  sx={{ fontFamily: "Bayon" }}
                >
                  Lifetime
                </Typography>
                <Box sx={{
                    display: "flex",
                    direction: "column",
                    justifyContent: "center",
                    py: "5px"
                  }}>
                  <Typography variant="h5" sx={{px: "10px"}}>
                    $49.99
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <Typography>
                      one time purchase
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{display: "flex", justifyContent: "center"}}
                >
                  <Button variant="contained" color="secondary" onClick={() => onSubscribe('oneTime')}>
                    One Time Purchase
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Button
              onClick={() => onClose()}
              size="small"
              sx={{ position: "absolute", top: "0", right: "-15px" }}
            >
              <CloseIcon color="secondary"/>
            </Button>
            <Grid container spacing={{xs: 4, sm: 4, md: 8, lg: 12}}>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="h4"
                  align="center"
                  sx={{ fontFamily: "Bayon" }}
                >
                  Free Plan
                </Typography>
                <Box sx={{
                  display: "flex",
                  direction: "column",
                  justifyContent: "left",
                  py: "5px"
                }}>
                  <Typography sx={{px: "10px"}}>
                    <CheckIcon />
                  </Typography>
                  <Typography>
                    Create Notes From Presentations
                  </Typography>
                </Box>
                <Box sx={{
                  display: "flex",
                  direction: "column",
                  justifyContent: "left",
                  py: "5px"
                }}>
                  <Typography sx={{px: "10px"}}>
                    <CheckIcon />
                  </Typography>
                  <Typography>
                    Three Uploads Per Month
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="h4"
                  align="center"
                  sx={{ fontFamily: "Bayon" }}
                >
                  Premium Plan
                </Typography>
                <Box sx={{
                    display: "flex",
                    direction: "column",
                    justifyContent: "left",
                    py: "5px"
                  }}>
                  <Typography sx={{px: "10px"}}>
                    <CheckIcon />
                  </Typography>
                  <Typography>
                    Create Notes From Presentations
                  </Typography>
                </Box>
                <Box sx={{
                    display: "flex",
                    direction: "column",
                    justifyContent: "left",
                    py: "5px"
                  }}>
                  <Typography sx={{px: "10px"}}>
                    <DoneAllIcon />
                  </Typography>
                  <Typography>
                    Unlimited Uploads
                  </Typography>
                </Box>
                <Box sx={{
                    display: "flex",
                    direction: "column",
                    justifyContent: "left",
                    py: "5px"
                  }}>
                  <Typography sx={{px: "10px"}}>
                    <DoneAllIcon />
                  </Typography>
                  <Typography>
                    Higher Quality Notes
                  </Typography>
                </Box>
                <Box sx={{
                    display: "flex",
                    direction: "column",
                    justifyContent: "left",
                    py: "5px"
                  }}>
                  <Typography sx={{px: "10px"}}>
                    <DoneAllIcon />
                  </Typography>
                  <Typography>
                    Unlimited File Upload Size
                  </Typography>
                </Box>
                <Box
                  sx={{display: "flex", justifyContent: "center", position: {sm: "", md: "absolute"}, bottom: "15px", left: "50%", transform: {sm: "", md: "translateX(-50%)"}}}
                >
                  <Button variant="contained" color="secondary" onClick={() => onUpgrade()}>
                    Upgrade Plan
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="h4"
                  align="center"
                  sx={{ fontFamily: "Bayon" }}
                >
                  Enterprise Plan
                </Typography>
                <Box sx={{
                    display: "flex",
                    direction: "column",
                    justifyContent: "left",
                    py: "5px"
                  }}>
                  <Typography sx={{px: "10px"}}>
                    <CheckIcon />
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <Typography>
                      Create Notes From Presentations
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{
                    display: "flex",
                    direction: "column",
                    justifyContent: "left",
                    py: "5px"
                  }}>
                  <Typography sx={{px: "10px"}}>
                    <DoneAllIcon />
                  </Typography>
                  <Typography>
                    Unlimited Uploads
                  </Typography>
                </Box>
                <Box sx={{
                    display: "flex",
                    direction: "column",
                    justifyContent: "left",
                    py: "5px"
                  }}>
                  <Typography sx={{px: "10px"}}>
                    <DoneAllIcon />
                  </Typography>
                  <Typography>
                    Higher Quality Notes
                  </Typography>
                </Box>
                <Box sx={{
                    display: "flex",
                    direction: "column",
                    justifyContent: "left",
                    py: "5px"
                  }}>
                  <Typography sx={{px: "10px"}}>
                    <DoneAllIcon />
                  </Typography>
                  <Typography>
                    Unlimited File Upload Size
                  </Typography>
                </Box>
                <Box sx={{
                    display: "flex",
                    direction: "column",
                    justifyContent: "left",
                    py: "5px"
                  }}>
                  <Typography sx={{px: "10px"}}>
                    <DoneAllIcon />
                  </Typography>
                  <Typography>
                    Shared Note Repository Across Teams
                  </Typography>
                </Box>
                <Box
                  sx={{display: "flex", justifyContent: "center"}}
                >
                  <Button variant="contained" color="secondary" onClick={() => (window.location = "mailto:contactus@slidenotes.dev ?subject=Contact%20SlideNotes%20Team")}>
                    Contact Us
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </Modal>
  );
}

export default PremiumModal;
