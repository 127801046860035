const handleUpload = (e, premium, _wrongType, _tooLarge, _handleUpload) => {
    if (!e.target.files) {
        _wrongType() 
        return
      }
      const file = e.target.files[0];
      if (!premium && file.size > 5242880) {
        _tooLarge() 
        return
      }
      _handleUpload(file)
}

export default handleUpload