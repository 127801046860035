import React from "react";
import { Typography, Grid, Button, Box, Divider } from "@mui/material";
import LoadingPage from "./LoadingPage";
import handleUpload from "../helpers/handleUpload";
import RemoveModal from "../components/DeleteModal";
import deleteNotes from "../helpers/deleteNotes.js";
import SurveyCard from "../components/SurveyCard.js";

function HomePage(props) {
  const [notes, setNotes] = React.useState(null);
  const [idToRemove, setIdToRemove] = React.useState("");

  const onRemove = React.useCallback(() => {
    try {
      deleteNotes(idToRemove);
    } catch (err) {
      props.createAlert("error", err.message);
    }
  }, [idToRemove, props])

  const closeRemoveModal = () => {
    setIdToRemove("");
  }

  const fileInput = React.useRef();

  const wrongType = () =>
    props.createAlert("error", "Please upload a pdf or pptx file.");
  const tooLarge = () =>
    props.createAlert("error", "The max size for free users is 5MB.");

  const openSurveyModalIfNotSeen = React.useCallback(() => {
    const hasSeenSurvey = localStorage.getItem("hasSeenSurveyModal");
    if (!hasSeenSurvey) {
      props.openSurveyModal();
      localStorage.setItem("hasSeenSurveyModal", true);
    }
  }, [props]);

  React.useEffect(() => {
    const url =
      "https://kodexs1t24.execute-api.us-west-2.amazonaws.com/Stage/getUserNotes";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        sessionToken: localStorage.getItem("sessionToken"),
      }),
    };
    fetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Error");
      })
      .then((data) => {
        const sortedNotes = data["items"].sort((a, b) => { return b["timeCreated"] - a["timeCreated"] })
        setNotes(sortedNotes);
        /* For Survey
        if (sortedNotes.length > 0) {
          openSurveyModalIfNotSeen();
        }
        */
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [props, openSurveyModalIfNotSeen]);

  return (
    <>
      {notes == null ? (
        <LoadingPage />
      ) : (
        <>
          <RemoveModal open={idToRemove.length > 0} onClose={() => closeRemoveModal()} onRemove={() => onRemove()} />
          <Grid
            container
            sx={{ alignContent: "flex-start", flexWrap: "wrap-reverse", p: 3 }}
          >
            <Grid item xs={12} sm={9} >
              <Typography noWrap variant="h3" sx={{ textTransform: "uppercase", fontFamily: "Bayon"}}>
                Your Notes
              </Typography>
              <Divider />
              {notes.map((note, index) => {
                console.log(note)
                return (
                  <Box>
                    <Button
                      key={index}
                      variant="text"
                      color="secondary"
                      onClick={() => (window.location = "/" + note["id"])}
                      fullWidth={true}
                      sx={{ justifyContent: "flex-start" }}
                    >
                      <Box>
                        <Typography key={index+"Date"} sx={{textAlign: "left", color: "gray"}}>
                          {new Date(note["timeCreated"] * 1000).toLocaleString("en-US", {"day": "numeric", "month": "short", "year": "numeric"})}
                        </Typography>
                        <Typography key={index} align="left" sx={{
                          fontFamily: 'DM Sans',
                        }}>
                          {note["s3key"].split(".")[0]}
                        </Typography>
                      </Box>
                    </Button>
                    <Button
                      key={index+"Delete"}
                      variant="text"
                      color="secondary"
                      sx={{color: "gray", textTransform: "none", fontStyle: "italic", justifyContent: "flex-start"}}
                      onClick={() => setIdToRemove(note["id"])}
                    >
                      Delete
                    </Button>
                    <Divider />
                  </Box>
                );
              })}
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box display="flex" justifyContent="flex-end">

                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => fileInput.current.click()}
                  sx={{ px: 4, py: 1, mb: "20px", mt: 1, fontWeight: 900, width: {xs: "100%", sm: "auto"} }}
                >
                New Note
                </Button>
              </Box>
              {/* For Survey
              notes.length > 0 && <SurveyCard 
                sx = {{
                  mb: 2,
                  ml: {xs: 0, sm: 2},
                }}
                openSurvey={() => props.openSurvey()}
              />
              */}
              <input
                ref={fileInput}
                hidden
                accept="application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf"
                type="file"
                onChange={(e) =>
                  handleUpload(e, props.premium, wrongType, tooLarge, (file) =>
                    props.progressPage(file)
                  )
                }
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default HomePage;
