import React, { useState } from "react";
import { Box, Button, Toolbar, AppBar, IconButton, Divider } from "@mui/material";
import logo from "../static/SlideNotesLogo.png";
import MenuIcon from "@mui/icons-material/Menu"

function Navbar(props) {

  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <Box position="sticky" top="0" zIndex={100}>
      <AppBar position="static">
        <Toolbar
          disableGutters={true}
          sx={{ pl: "10px", pr: "10px", justifyContent: "space-between" }}
        >
          <Box
            sx={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                pt: 1,
              }}
            >
              <a href="/">
                <img src={logo} alt="SlideNotes" height="50px"/>
              </a>
            </Box>
            {props.loggedIn == null ? (
              <></>
            ) : !props.loggedIn ? (
              <>
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => (window.location.href = "/")}
                  sx={{ textTransform: "none", display: { xs: "none", sm: "block" } }}
                  size="large"
                >
                  Home
                </Button>
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => props.onTestimonials()}
                  sx={{ textTransform: "none", display: { xs: "none", sm: "block" } }}
                  size="large"
                >
                  Testimonials
                </Button>
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => props.onFaq()}
                  sx={{ textTransform: "none", display: { xs: "none", sm: "block" } }}
                  size="large"
                >
                  FAQ
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => (window.location.href = "/")}
                  sx={{ textTransform: "none", display: { xs: "none", sm: "block" } }}
                  size="large"
                >
                  My Notes
                </Button>
              </>
            )}
            <Button
              variant="text"
              color="secondary"
              onClick={() => (window.location = "mailto:contactus@slidenotes.dev ?subject=Contact%20SlideNotes%20Team")}
              sx={{ textTransform: "none", display: { xs: "none", sm: "block" } }}
              size="large"
            >
              Contact Us
            </Button>
          </Box>
          <Box
            sx={{ display: { xs: "none", sm: "block" }}}
          >
            {props.loggedIn == null ? (
              <></>
            ) : !props.loggedIn ? (
              <>
                <Button
                  color="secondary"
                  onClick={props.login}
                  sx={{ textTransform: "none" }}
                  size="large"
                >
                  Login
                </Button>
                <Button
                  color="secondary"
                  onClick={props.login}
                  variant="contained"
                  sx={{ fontWeight: 900 }}
                >
                  Sign up
                </Button>
              </>
            ) : (
              <>
                {(!props.premium || props.subscribed) && 
                  <>
                    {props.premium && 
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={props.onLifetimeSubscribe}
                        size="small"
                        sx={{
                          mx: { xs: 0, sm: 2 },
                          px: 1,
                          textTransform: "none",
                        }}
                      >
                        Get SlideNotes for Life
                      </Button>
                    }
                    <Button
                      variant={props.premium ? "text" : "contained"}
                      color="secondary"
                      onClick={
                        props.premium ? props.onManageSubscribe : props.onSubscribe
                      }
                      size={props.premium ? "large" : "small"}
                      sx={{
                        mx: { xs: 0, sm: 2 },
                        px: 1,
                        textTransform: props.premium ? "none" : "uppercase",
                      }}
                    >
                      {props.premium ? "Manage Subscription" : "Get Premium"}
                    </Button>
                  </>
                }
                <Button
                  color="secondary"
                  onClick={props.logout}
                  size="large"
                  sx={{ 
                    px: 0,
                    textTransform: "none",
                  }}
                >
                  Logout
                </Button>
              </>
            )}
          </Box>
          <Box
            sx={{ display: { xs: "block", sm: "none" }, pt: 1 }}
          >
            <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
              <MenuIcon fontSize="large"/>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      { drawerOpen &&
        <Box
          sx={{
            position: "fixed",
            top: "60px",
            width: "100%",
            height: "100%",
            zIndex: 100,
          }}
        >
          <Box
            sx={{
              position: "fixed",
              top: "65px",
              width: "100%",
              height: "min-content",
              zIndex: 101,
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              justifyContent: "top",
              alignItems: "center",
              pb: 2,
            }}
          >
            
            {props.loggedIn == null ? (
              <></>
            ) : !props.loggedIn ? (
              <>
                <Divider />
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => (window.location.href = "/")}
                  sx={{ textTransform: "none" }}
                  size="large"
                >
                  Home
                </Button>
                <Divider />
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => props.onTestimonials()}
                  sx={{ textTransform: "none" }}
                  size="large"
                >
                  Testimonials
                </Button>
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => props.onFaq()}
                  sx={{ textTransform: "none" }}
                  size="large"
                >
                  FAQ
                </Button>
                <Box>
                  <Button
                    color="secondary"
                    onClick={props.login}
                    sx={{ textTransform: "none" }}
                    size="large"
                  >
                    Login
                  </Button>
                  <Button
                    color="secondary"
                    onClick={props.login}
                    variant="contained"
                    sx={{ fontWeight: 900 }}
                  >
                    Sign up
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Divider />
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => (window.location.href = "/")}
                  sx={{ textTransform: "none" }}
                  size="large"
                >
                  My Notes
                </Button>
                <Divider />
                <Box>
                  {(!props.premium || props.subscribed) && 
                    <>
                      {props.premium && 
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={props.onLifetimeSubscribe}
                          size="small"
                          sx={{
                            mx: { xs: 0, sm: 2 },
                            px: 1,
                            textTransform: "none",
                          }}
                        >
                          Get SlideNotes for Life
                        </Button>
                      }
                      <Button
                        variant={props.premium ? "text" : "contained"}
                        color="secondary"
                        onClick={
                          props.premium ? props.onManageSubscribe : props.onSubscribe
                        }
                        size={props.premium ? "large" : "small"}
                        sx={{
                          mx: { xs: 0, sm: 2 },
                          px: 1,
                          textTransform: props.premium ? "none" : "uppercase",
                        }}
                      >
                        {props.premium ? "Manage Subscription" : "Get Premium"}
                      </Button>
                    </>
                  }
                  <Button
                    color="secondary"
                    onClick={props.logout}
                    size="large"
                    sx={{ 
                      px: 0,
                      textTransform: "none",
                    }}
                  >
                    Logout
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Box>
      }
    </Box>
  );
}

export default Navbar;
