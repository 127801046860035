import React from "react";
import { Typography, Box, Grid, Button, Container } from "@mui/material";
import Calculus from "../static/Calculus.jpg";
import SoftwareEng from "../static/SoftwareEng.jpg";
import CellTheory from "../static/CellTheory.jpg";
import ScuMentalHealthConnect from "../static/ScuMentalHealthConnect.png";
import PrioritiesPM from "../static/PrioritiesPm.png";
import ProductManagementGuide from "../static/ProductManagementGuide.png";
import Faq from "react-faq-component";
import TestimonialsCarousel from "../components/TestimonialsCarousel";

const faqs = {
  rows: [
    {
      title: "What is SlideNotes?",
      content: "SlideNotes is a tool that effortlessly converts presentations and PDFs into notes automatically."
    },
    {
      title: "How does SlideNotes convert slides/pdfs  into notes?",
      content: "SlideNotes converts slides into notes by identifying key words and phrases in the uploaded slides/documents and summarizes them into comprehensive bulleted notes."
    },
    {
      title: "What file formats are supported by SlideNotes?",
      content: "SlideNotes supports PowerPoint (.pptx) and PDF files. Please contact us if there are more file types you would like to see supported going forward."
    },
    {
      title: "Which countries does SlideNotes support?",
      content: "We currently only support usage in the United States and Canada."
    },
    {
      title: "Can I use SlideNotes if I am a student?",
      content: "Yes! SlideNotes was born in the heart of our dorm room, conceived as the solution to transform lengthy lecture slides into crisp, concise notes. We intimately understand the struggle of dedicating excessive time to note-taking, only to realize, right before the test, that there are no notes to review. SlideNotes emerged as our remedy to this common dilemma, designed to streamline the process of turning extensive slides into digestible nuggets of information, saving both time and study stress. Don’t believe us? Look at our testimonials!"
    },
    {
      title: "Is SlideNotes useful for business professionals?",
      content: "Absolutely! SlideNotes was crafted with the professional world in mind. Whether you're a business executive, project manager, or entrepreneur, SlideNotes simplifies the conversion of detailed presentations/PDFs into actionable notes. We get the challenges of time constraints and the need for effective communication in the business realm. SlideNotes is your ally, ensuring that crucial insights are condensed and accessible, transforming your meetings and presentations into streamlined and productive experiences. Don’t believe us? Look at our testimonials!"
    }]
}

function LandingPage(props) {
  const commonFontStyle = {
    fontFamily: "DM Sans, sans-serif",
  };

  return (
    <Grid
      container
      spacing={2}
      columns={{ xs: 12, sm: 12, md: 14, lg: 14 }}
      sx={{
        flexGrow: 1,
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        p: 3,
      }}
    >
      <Grid item xs={12} md={12}>
        <Typography variant="h2" sx={{ textTransform: "uppercase", fontFamily: "Bayon" }}>SlideNotes</Typography>
        <Typography variant="h5">
          Transform presentations into readable notes with ease!
        </Typography>
        <Typography>
          Just upload any .pptx or .pdf file and obtain notes you can easily
          review and share.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => props.handleGetStarted()}
          sx={{ px: 4, py: 1, mt: 1, fontWeight: 900 }}
        >
          Try It
        </Button>
      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={4} sx={{ display: "flex", justifyContent: "center" }}>
        <a href="https://www.aitoolhunt.com/tool/slidenotes.dev?utm_medium=featured&utm_source=slidenotes.dev" target="_blank"><img width="224" src="https://www.aitoolhunt.com/images/featured-light.png?a=1" /></a>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={4} sx={{ display: "flex", justifyContent: "center" }}>
        <a href="https://www.producthunt.com/products/slidenotes?utm_source=badge-follow&utm_medium=badge&utm_souce=badge-slidenotes" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/follow.svg?product_id=533763&theme=light" width="250" height="54" /></a>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={4} sx={{ display: "flex", justifyContent: "center" }}>
        <a href="https://theresanaiforthat.com/ai/slidenotes/?ref=featured&v=763198" target="_blank" ><img height="55" src="https://media.theresanaiforthat.com/featured2.png" style={{ border: "1px solid black", borderRadius: "10px", background: "white" }} /></a>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={14} sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{

          display: 'flex',
          alignItems: 'top',
          width: { md: '100%', lg: '85%' },
          padding: '20px',
          backgroundColor: '#E1E8F0',
          borderRadius: '10px',
          flexWrap: "wrap"
        }}>
          <Box sx={{ flex: 1, padding: '10px', minWidth: '400px' }}>
            <Typography
              variant="h4"
              sx={{
                fontFamily: 'Bayon',
              }}
            >
              Work Smarter, Not Harder
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontFamily: 'DM Sans',
              }}
            >
              Making Studying a Breeze
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'DM Sans',
                color: '#696868'
              }}
            >
              SlideNotes is your academic sidekick. Simplify your study routine by converting presentations into notes.<br/><br/>We're here to spark curiosity and make learning straightforward for every student, delivering a practical path to success.<br/><br/>Don’t believe us? See Luke’s testimonial:
            </Typography>
          </Box>
          <Box sx={{ flex: 1, textAlign: 'right', padding: '10px', minWidth: '400px' }}>
            <Typography
              variant="h6"
              sx={{
                fontFamily: 'DM Sans',
              }}
            >

              "SlideNotes has been a game-changer for my essay writing. It summarizes the main points of articles for me. This tool has saved me so much time and effort, allowing me to focus on crafting well-informed and impactful essays. I highly recommend SlideNotes to any student looking to streamline their research process and boost the quality of their writing!"
              <br />
              <br />
              - Luke P, Santa Clara University
            </Typography>
          </Box>


        </Box>
      </Grid>



      <Grid item xs={12} sm={12} md={12} lg={14}>
        {
          <Typography variant="h4" sx={{ textTransform: "uppercase", fontFamily: "Bayon", textAlign: "center" }}>Example Notes</Typography>
        }
      </Grid>


      <Grid item xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap" }}>
        <a href="https://www.slidenotes.dev/cd737f4c-7235-4590-a824-0c4d88b44814" target="_blank"><img src={Calculus} width="350" /></a>
        <a href="https://www.slidenotes.dev/c951f8dc-107f-4266-bea6-11116ed04d20" target="_blank"><img src={SoftwareEng} width="350" /></a>
        <a href="https://www.slidenotes.dev/69110e4e-d113-4ca7-a182-ed1d44c14fbc" target="_blank" ><img src={CellTheory} width="350" /></a>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={14} sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'top',
          width: { md: '100%', lg: '85%' },
          padding: '20px',
          backgroundColor: '#E1E8F0',
          borderRadius: '10px',
          flexWrap: "wrap-reverse"
        }}>
          <Box sx={{ flex: 1, padding: '10px', minWidth: '400px' }}>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ pr: '20px' }}>
                <img src={ScuMentalHealthConnect} width="200" />
              </Box>
              <Box>
                <Typography variant="body1" sx={{ color: '#696868' }}>
                  12 Dec 2023
                </Typography>
                <Typography variant="h6">
                  Mental Health Connect
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'space-between' }}>
              <Box sx={{ pr: '20px' }}>
                <img src={PrioritiesPM} width="200" />
              </Box>
              <Box>
                <Typography variant="body1" sx={{ color: '#696868' }}>
                  20 Nov 2023
                </Typography>
                <Typography variant="h6">
                  Priorities: Making Things Happen (PM)
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'space-between' }}>
              <Box sx={{ pr: '20px' }}>
                <img src={ProductManagementGuide} width="200" />
              </Box>
              <Box>
                <Typography variant="body1" sx={{ color: '#696868' }}>
                  13 Nov 2023
                </Typography>
                <Typography variant="h6">
                  Product Management Guide (New Hire)
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ flex: 1, textAlign: 'right', padding: '10px', minWidth: '400px' }}>
            <Typography
              variant="h4"
              sx={{
                fontFamily: 'Bayon',
              }}
            >
              Versatile Learning
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontFamily: 'DM Sans',
              }}
            >
              Crafting Success Across Essays, Exams, and Every Study Material
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'DM Sans',
                color: '#696868',
                textAlign: 'left'
              }}
            >
              Your tool for success in both academics and the workplace. Whether you're a student preparing for exams or a professional turning meeting slides into actionable notes, SlideNotes simplifies the process.
              <br />
              <br />
              Effortlessly distill information, save time, and boost your productivity. SlideNotes adapts to your needs, making learning and post-meeting tasks straightforward and effective.
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid id="Testimonials" item xs={12} sm={12} md={12} lg={14} sx={{ display: "flex", justifyContent: "center", flexDirection: "column"}}>
        <Box sx={{pb: 1}}>
          <Typography variant="h4" sx={{ textTransform: "uppercase", fontFamily: "Bayon", textAlign: "center" }}>Testimonials</Typography>
        </Box>
              
        <Box sx={{mx: "auto", width: {xs: '100%', sm: '100%', md: '100%', lg: '90%'}}}>
          <TestimonialsCarousel/>
        </Box>
              
        <Box sx={{mx: "auto", pt: 1}}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => (window.location = "mailto:contactus@slidenotes.dev ?subject=Leave%20A%20Testimonial%20for%20SlideNotes!")}
            sx={{ px: 4, py: 1, mt: 1, fontWeight: 900 }}
          >
            Leave A Testimonial
          </Button>
        </Box>
      </Grid>



      <Grid item xs={12} sm={12} md={12} lg={14}>
        <Container sx={{ width: { md: '100%', lg: '85%' } }} disableGutters={true}>
          <div id="FAQ">
          <Typography variant="h3" sx={{ textTransform: "uppercase", fontFamily: "Bayon", textAlign: "left" }}>FAQ</Typography>
          </div>
          <Faq
            data={faqs}
            styles={{
              bgColor: "f1f1f1",

            }}
            titleStyle={{
              fontSize: '1.2em',
              fontWeight: 'bold',
              fontFamily: commonFontStyle.fontFamily,
            }}
          />
        </Container>
      </Grid>

    </Grid>


  );
}

export default LandingPage;
